import { AppProps } from 'next/app';
import Head from 'next/head';
import { MantineProvider } from '@mantine/core';
import { usePostHog } from 'next-use-posthog'
// import '../styles/style.css';
import { UserProvider } from '@auth0/nextjs-auth0';
import '../styles/output.css';
// if (typeof window !== 'undefined') {
//   // we only want to call this init function on the frontend, so we check typeof window !== 'undefined'
//   SuperTokensReact.init(frontendConfig())
// }

function MyApp(props: AppProps) {
    const { Component, pageProps } = props;
    usePostHog(process.env.NEXT_PUBLIC_POSTHOG_API_KEY, {
        api_host: 'https://app.posthog.com',
        loaded: (posthog) => {
            if (process.env.NODE_ENV === 'development') posthog.opt_out_capturing()
        },
    })
    const { user } = pageProps;
    return (
        <div >
            <Head>
                <title>Claros Labs</title>
                <link rel="icon" href="/favicon.ico" />
                <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
            </Head>
                <UserProvider user={user}>
                    <MantineProvider
                        theme={{
                            colorScheme: 'light',
                        }}
                     >
                        <Component {...pageProps} />
                    </MantineProvider>
                </ UserProvider>
        </div>
    );
}

export async function getStaticProps(context) {
    return {
        props: {
            user: ""
        }, // will be passed to the page component as props
    }
}

export default MyApp
